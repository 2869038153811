.chatUserInputBox {
  padding: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #c4c7c5;
  border-radius: 10px;
}

.emojiS {
  width: 30px;
  align-items: center;
  justify-content: center;
  display: flex;
  border-radius: 50%;
  /* cursor: pointer; */
  /* margin-left: 15px; */
}

.emojiS img {
  width: 100%;
}

.mic {
  width: 24px;
  align-items: center;
  justify-content: center;
  display: flex;
  border-radius: 50%;
  /* cursor: pointer; */
}

.mic img {
  width: 100%;
}

.submitBtnActive {
  cursor: pointer;
  width: 30px;
  align-items: center;
  justify-content: center;
  display: flex;
  /* margin-right: 5px; */
  /* border: 1px solid gray; */
}

.removePointer {
  cursor: unset !important;
}

.submitBtnActive img {
  width: 100%;
}

.chatUserInputItem {
  width: calc(100% - 93px);
  align-items: center;
  display: flex;
  overflow: auto;
  border-radius: calc(48px / 2);
}

.chatUserInputItem > input[type="text"] {
  height: 40px;
  width: 100%;
  border-radius: calc(48px / 2);
  outline: 0px solid transparent;
  border: none;
  font-size: 14px;
  background-color: transparent;
}

@media only screen and (max-width: 768px) {
  .ChatUserInputBox_chatUserInputItem__2lSoL > input[type="text"] {
    height: 34px;
  }

  .submitBtnActive {
    width: 30px;
  }
  .chatUserInputBox {
    margin: auto;
    width: 95%;
  }
  .chatUserInputItem > input[type="text"] {
    height: 30px;
  }

  /* .emojiS {
    width: 18px;
  } */

  .mic {
    width: 18px;
  }
}
