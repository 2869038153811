.documentListing {
  height: calc(100% - 144px);
  /* background-color: #ffffff; */
  padding: 5px;
}

.documentListingInner {
  height: 100%;
  /* border: 1px solid #c4c7c5; */
  overflow: auto;
  scroll-behavior: smooth;
}

.docment {
  padding: 1px 5px;
  margin-left: 10px;
}

.docment > .name > a {
  font-size: 14px;
  color: var(--balck-color-1);
}

.docment > .detail {
  color: var(--gray-color-1);
  font-size: 14px;
}
