.documentSearch {
  padding: 10px 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.documentSearchInner {
  background-color: #ffffff;
  border-bottom: 1px solid #c4c7c5;
  border: 1px solid #d1cbcb;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 90%;
  border-radius: 5px;
}

.searchIcon {
  width: 24px;
  align-items: center;
  justify-content: center;
  display: flex;
  border-radius: 50%;
  cursor: pointer;
  margin-right: 10px;
}

.searchIcon img {
  width: 100%;
}

.searchInput {
  width: calc(100% - 50px);
  align-items: center;
  display: flex;
  overflow: auto;
  border-radius: calc(48px / 2);
  padding: 5px;
}

.searchInput > input[type="text"] {
  height: 20px;
  width: 100%;
  border-radius: calc(48px / 2);
  outline: 0px solid transparent;
  border: none;
  font-size: 14px;
}
