.chatItem {
  min-height: 30px;
  border-radius: 5px;
  display: flex;
  color: #ffffff;
}

.rightSide {
  flex-direction: row-reverse;
}

.userTitle {
  font-weight: 600;
  margin-bottom: 5px;
}

.chatItemInner {
  display: flex;
  border-radius: 6px;
  position: relative;
  max-width: 93%;
  z-index: 1;
}

.rightSideReverse {
  flex-direction: row-reverse;
}

.chatItemTools {
  /* max-width: 25px;
  min-width: 25px; */
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  display: flex;
}

.chatItemToolsReverse {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border-top-right-radius: unset !important;
  border-bottom-right-radius: unset !important;
}

.chatItemTools img {
  width: 18px;
}

.chatItemTool {
  cursor: pointer;
  padding: 0 5px;
  margin-top: 7px;
  position: relative;
}

.copyTool path {
  fill: green;
}

.chatItemContent {
  font-size: 14px;
  /* background-color: #5385cb; */
  padding: 10px;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  color: var(--balck-color-1);
}

.chatItemContent2 {
  border-radius: 5px;
  background-color: transparent;
}

.chatItemContentReverse {
  color: #4e4e4e !important;
  background-color: #e7e7e7;
  border-radius: 5px;
}

.chatUserIcon {
  max-width: 18px;
  min-width: 18px;
  padding: 10px;
}
.chatGenieIcon {
  max-width: 18px;
  min-width: 18px;
  padding: 10px;
}

.chatItemIcon img {
  width: 100%;
}

.chatOptions {
  padding: 3px;
  /* display: flex; */
  margin-top: 10px;
}

.chatOption {
  padding: 5px;
  border-radius: 5px;
  margin-right: 11px;
  font-size: 13px;
  color: var(--balck-color-1);
  cursor: pointer;
}

.chatOption:hover {
  /* background: #3674bc; */
  color: #000000;
  border-radius: 3px;
}

.chatOption a {
  /* color: #000000; */
  text-decoration: underline;
  color: var(--link-color);
}

.chatOption a:hover {
  border-bottom: 1px solid #ffffff;
}

.chatItem .likeDislike {
  position: absolute;
  bottom: -20px;
  right: 11px;
  display: flex;
  justify-content: center;
  padding: 2px;
  border-radius: 5px;
}

.chatItem .likeDislike .dislikeBtn {
  margin-left: 5px;
}

.copied {
  padding: 2px;
  top: -22px;
  position: absolute;
  background-color: #505050;
  border-radius: 4px;
  font-size: 11px;
  right: -9px;
  width: 47px;
  text-align: center;
}

.preLoader {
  width: 100px;
  height: 100%;
}
.textStyle {
  line-height: 20px;
  text-align: justify;
  word-break: break-word;
}

@media only screen and (max-width: 768px) {
  /* .chatItemTools {
    width: 44px;
    max-width: 22px;
    min-width: 22px;
  } */

  .chatItemInner {
    max-width: 90%;
  }

  .chatItemIcon {
    max-width: 15px;
    min-width: 15px;
  }

  .chatItemContent {
    font-size: 13px;
  }
}
