.container {
  border: 1px solid #ccc;
  width: 66%;
  /* height: 80%; */
  padding: 4px 15px 15px 15px;
  background-color: #ffffff;
  border-radius: 5px;
}

.heading {
  border-bottom: 1px solid #d1cbcb;
  height: 44px;
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
  color: var(--ybl-blue-color);
}

.headingText {
  width: calc(100% - 30px);
}

.suggestions {
  margin-top: 10px;
  height: 200px;
  overflow: auto;
}

.suggestion {
  padding: 10px;
  border-radius: 5px;
  background-color: #f6fafe;
  margin-top: 10px;
  font-size: 14px;
  color: var(--balck-color-1);
  cursor: pointer;
  border: 1px solid #f6fafe;
}

.suggestion:hover {
  color: var(--ybl-blue-color);
  border: 1px solid var(--ybl-blue-color);
}

.closeBtn {
  cursor: pointer;
  font-size: 15px;
  color: var(--balck-color-1);
  font-weight: 600;
  position: absolute;
  right: 0;
}

.noSuggestion {
  text-align: center;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media only screen and (max-width: 768px) {
  .container {
    width: 85%;
  }

  .suggestion {
    font-size: 13px;
    margin-top: 6px;
    padding: 7px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .heading {
    font-size: 15px;
  }
}
