.header {
  height: 60px;
}

.webHeader {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  height: 100%;
}

.headerTitle {
  text-align: center;
}

.headerTitle1 {
  color: var(--ybl-blue-color);
  font-size: 26px;
}

.headerTitle2 {
  color: var(--gray-color-1);
  font-size: 12px;
}

.mobileMenu {
  width: 30px;
  /* border: 1px solid gray; */
  right: 0;
  position: absolute;
  cursor: pointer;
  display: none;
}

.mobileChatDocumentWindow {
  display: none;
}

.resetChatHistory {
  width: 40px;
  height: 40px;
  top: 10px;
  left: 3%;
  border: 1px solid #ff3e1d;
  position: absolute;
  border-radius: 50%;
  text-align: center;
  cursor: pointer;
}

.resetChatHistory .resetImg {
  width: 17px;
  height: 21px;
  text-align: center;
  margin: auto;
}

.resetText {
  color: #ff3e1d;
  margin-top: -7px;
  font-size: 14px;
}

.header .mobileHeader {
  display: none;
  background-color: #5385cb;
  width: 100%;
  height: 99%;
  border-bottom: 1px solid #326ec0;
}

.header .mobileHeader .mobileHeaderLeft {
  width: 50%;
  display: flex;
  /* justify-content: center; */
  align-items: center;
}

.header .mobileHeader .mobileHeaderLeft .headerLogo {
  width: 35px;
  height: 35px;
  background-color: #ffffff;
  margin-left: 10px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.header .mobileHeader .mobileHeaderLeft .headerLogo .headerLogoInner {
  width: 12px;
}

.header .mobileHeader .mobileHeaderLeft .headerLogo .headerLogoInner img {
  width: 100%;
}

.header .mobileHeader .mobileHeaderLeft .headerText {
  color: #ffffff;
  margin-left: 10px;
}

.header .mobileHeader .mobileHeaderRight {
  width: 50%;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
}
.header .mobileHeader .mobileHeaderRight > img {
  width: 50%;
}
.header .resetImg.mhrItem {
  margin-right: 5px;
  width: 35px;
  height: 35px;
}
.header .mhrItem.resetImg img {
  width: 100%;
  cursor: pointer;
}

@media only screen and (max-width: 768px) {
  .resetChatHistory {
    width: 35px;
    height: 35px;
    top: 7px;
    right: 10px;
    border: unset;
    position: absolute;
    border-radius: 50%;
    text-align: center;
    cursor: pointer;
    background: #ffffff;
    left: unset;
  }

  .resetChatHistory .resetText {
    font-size: 11px;
  }
  .mobileMenu {
    display: none;
  }

  .header {
    height: 50px;
  }

  .webHeader {
    display: none;
  }

  .header .mobileHeader {
    display: flex;
  }
}
