.chatDocumentsContainer {
  background-color: var(--bg-transparent-color);
  border-left: 1px solid #c4c7c5;
  height: 100%;
}
.header {
  padding: 8px;
  text-align: center;
  font-size: 18px;
  color: var(--white-color);
  background-color: var(--ybl-blue-color);
  /* border-top-right-radius: 10px; */
}
