.mainContainer {
  height: 97%;
  position: relative;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  /* background-image: url(../images/genie_background.svg); */
  position: relative;
}

.chatBox {
  height: calc(100% - 66px);
  overflow-y: auto;
  padding: 10px 0;
  scroll-behavior: smooth;
}

.chatUserInput {
  /* border: 1px solid gray; */
}
.chatItem {
  margin-top: 17px;
}
.chatOptions {
  padding: 3px;
  /* display: flex; */
  margin-top: 10px;
}

.chatOption {
  padding: 5px;
  border-radius: 5px;
  border: 1px solid #2b61ab;
  padding: 5px 10px;
  margin-right: 11px;
  font-size: 11px;
  color: var(--balck-color-1);
  display: inline-block;
  margin-bottom: 11px;
  cursor: pointer;
}

.chatOption:hover {
  background: var(--ybl-blue-color);
  color: #ffffff;
  border-radius: 3px;
}

.chatOption a {
  text-decoration: none;
  color: #505050;
}

.chatOption a:hover {
  color: #ffffff;
  text-decoration: underline;
}

.loader {
  width: 100%;
  height: 100%;
}

.inputSearchPreloader {
  background-color: green;
  min-height: 50px;
  margin-left: 30px;
}

@media only screen and (max-width: 768px) {
  .chatBox {
    height: calc(100% - 58px);
  }
  .timeText {
    font-size: 11px;
  }
}

/* --- */
.disclaimer {
  height: calc(100% - 66px);
  overflow-y: auto;
  padding: 10px 0;
  scroll-behavior: smooth;
  /* background-color: aqua; */
}
