.timeLine {
  border: 1px solid #d1cbcb;
  position: relative;
  width: 84%;
  margin: auto;
  margin-top: 10px;
  height: 0;
  font-size: 11px;
  /* display: none; */
}

.timeText {
  background-color: var(--white-snow-color);
  margin: auto;
  padding: 0 10px;
  color: var(--gray-color-1);
  width: 115px;
  margin-top: -9px;
}
