.container {
  width: 100%;
  height: 100%;
  position: relative;
}

.disclaimer {
  bottom: 20px;
  position: absolute;
  margin: auto;
  left: 0;
  right: 0;
  color: var(--balck-color-1);
}

.disclaimerHeading {
  height: 100px;
  text-align: center;
}

.disclaimerHeadingImg {
}

.disclaimerHeadingText {
  font-size: 14px;
  font-weight: 500;
}

.disclaimerItems {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.disclaimerItem {
  width: 46%;
  border: 1px solid #c4c7c5;
  padding: 9px;
  border-radius: 10px;
  /* text-align: justify; */
  font-size: 14px;
}

/* suggestions */
.suggestions {
  width: 100%;
  height: 100%;
  position: absolute;
  bottom: 0;
  top: 0;
  background-color: #f6fafe;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media only screen and (max-width: 768px) {
  .disclaimerItems {
    padding: 8px;
    display: block;
  }
  .disclaimerItem {
    display: block;
    width: 90%;
    font-size: 13px;
    margin: auto;
    margin-top: 10px;
  }
}
