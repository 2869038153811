.container {
  margin-top: 5px;
}

.container .button {
  border: none;
  width: 28px;
  background-color: transparent;
  padding: 3px 0;
  cursor: pointer;
  position: relative;
}
#myPath:hover {
  fill: red; /* Change the fill color on hover */
  stroke: blue; /* Change the stroke color on hover */
}
/* 
button[title]:hover::after {
    content: attr(title);
    position: absolute;
    top: 2200px;
    left: -500px;
} */
