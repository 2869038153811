.mainContainer {
  /* background: #f3f3f3; */
  background: #ffffff;
  height: 100%;
}

.mainBody {
  height: calc(100% - 92px);
  display: flex;
  flex-direction: row;
  border: 1px solid #d1cbcb;
  width: 94%;
  margin: auto;
  /* border-radius: 10px; */
}

.chatWindow {
  background-image: url(../images/genie_background.svg);
  background-position: 0 0;
  background-repeat: no-repeat;
  width: 70%;
  background-color: var(--bg-transparent-color);
  position: relative;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  display: flex;
}

.chatWindowLeft {
  width: 152px;
}

.chatWindowRight {
  width: calc(100% - 178px);
}

.chatDocumentWindow {
  width: 30%;
}

@media only screen and (max-width: 768px) {
  .mainBody {
    width: 100%;
    border-radius: unset;
    height: calc(100% - 50px);
    border: 1px solid #d1cbcb;
    border: none;
  }

  /* For desktop: */
  .chatWindow {
    width: 100%;
  }

  .chatDocumentWindow {
    display: none;
  }

  .mobileChatDocumentWindow {
    display: block;
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
  }

  .mobileMenu {
    display: block;
  }

  .chatWindowLeft {
    width: 0;
  }

  .chatWindowRight {
    width: 100%;
  }
}
