.pagination {
  padding: 5px;
  height: 30px;
  background-color: #f1f7fe;
  border-bottom-right-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pagination .pageItems {
  font-size: 13px;
  padding: 1px 8px;
  cursor: pointer;
  color: #b1b1b1;
}

.pagination .selected {
  color: var(--ybl-blue-color);
  border-radius: 5px;
  text-decoration: underline;
}

.pagination .disabled {
  cursor: not-allowed !important;
  opacity: 0.4;
}
